import { MetaContainerDto } from "../../../Typedefs.gen";
import { IResponseMeta } from "./RequestHelper";

export interface IApiErrorData {
    title: string;
    details: string;
}

export interface IApiMeta<T> {
    meta: T;
}

export interface IApiError {
    error: IApiErrorData;
}

export interface IApiSuccess<T> {
    data: T;
    successful: boolean;
}

export interface IMetaContainerDto<T> extends MetaContainerDto {
    info: T;
}
export interface IMetaWithContainer<T = undefined> extends IResponseMeta<IMetaContainerDto<T>> {

}

export type TApiSuccess<T, M = void> = IApiSuccess<T> & IApiMeta<M>;
export type TApiError<M = void> = IApiError & IApiMeta<M>
export type TApiResponse<T, M = void> = (IApiSuccess<T> | IApiError) & IApiMeta<M>;

export function makeError<T>(title: string, details: string, meta: T): IApiError & IApiMeta<T> {
    return {error: {title, details: details}, meta};
}

export function makeDefaultError<T>(meta: T): IApiError & IApiMeta<T> {
    return makeError("Something unknown happened", "Something happened that we did not expect", meta);
}





interface IDataMethods<T> {
    list: () => TApiSuccess<T[]>;
    manage: (data: T) => TApiSuccess<T>;
    delete: (id: number) => TApiSuccess<void>;
}

interface IDataMethodsAsync<T> {
    list: () => Promise<TApiSuccess<T[]>>
    manage: (data: T) => Promise<TApiSuccess<T>>;
    delete: (id: number) => Promise<TApiSuccess<void>>;
}

export interface IDataRoute<T> extends IDataMethodsAsync<T>
{

}