import * as React from "react";
import { BasePage } from "../BasePage";
import { RouteComponentProps } from "react-router-dom";
import { SegmentedHeader } from "../../components/Shared/SegmentedHeader";
import { Dimmer, Grid, Loader, Image, Segment, Form, InputOnChangeData, TextAreaProps, Confirm, Button, Select, DropdownProps, GridColumn } from "semantic-ui-react";
import { MyMapComponent } from "../../components/Shared/GoogleMap";
import { UploadFile } from "../../components/Shared/UploadFile";
import { ApiRequest } from "../../components/Shared/Network/RequestHelper";
import { initialObjectImage, initialPageContent, ObjectImage, PageContent, PageType, PositionContentType } from "../../Typedefs.gen";
import { Session } from "../../components/Shared/Network/Session";
import { FormEvent } from "react";
import { toast } from "react-toastify";

enum Mode {
    Create,
    Edit
}

const positionOptions = [
    { key: '0', value: '0', text: 'Снимка-Ляво Текст-Дясно' },
    { key: '1', value: '1', text: 'Снимка-Дясно Текст-Ляво' },
    { key: '2', value: '2', text: 'Снимка-Горе Текст-Долу' },
    { key: '3', value: '3', text: 'Снимка-Долу Текст-Горе' }
]


interface IProps {
}

interface IState {
    loading: boolean;
    loadingMessage: string;
    confirmCancel: boolean;
    confirmSave: boolean;
    confirmDelete: boolean;
    form: PageContent[];
    mode: Mode;
    index: number;
    isMobile: boolean;
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class HomeEditorPageImpl extends BasePage<TPropsImpl, TStateImpl> {
    
    constructor(props: TPropsImpl) {
        super(props);

        var idNum = parseInt(props.match.params.id as string);
        var id = isNaN(idNum) ? -1 : idNum;
        var mode = (id == -1) ? Mode.Create : Mode.Edit;


        this.state = {
            loading: false,
            loadingMessage: "",
            confirmCancel: false,
            confirmSave: false,
            confirmDelete: false,
            form: [],
            mode: mode,
            index: id,
            isMobile: document.documentElement.clientWidth < 769 ? true : false,

        };

    }

    componentDidMount() {
        if(!Session.hasAuthKey()){
            this.navigate("/home")
        }

        window.addEventListener('resize', this.handleWindowSizeChange);

        this.setDocumentTitle("За нас - редактиране");

        this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        var currentWidth = document.documentElement.clientWidth < 500 ? true : false;;
        if (currentWidth != this.state.isMobile) {
            this.setState({isMobile: currentWidth})
        }
    };

    loadData(){
        this.setState({loading: true, loadingMessage: "Loading home ..."});

        ApiRequest.post("/api/home/GetByType",{PageType: PageType.Home}).then(r => {
            let data: PageContent[] = r.data as PageContent[];
            this.setState({form: data});
        })

        this.setState({loading: false, loadingMessage: ""});

    }

    navigate(link: string) {
        this.props.history.push(link);
    }



    onFormInputChanged = (event: any, data: InputOnChangeData) => {
        this.changeFormProp(data.name, data.value, data.index);
    }

    onFormTextAreaChanged = (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        let msg = data.value ?  data.value.toString() : "";
        this.changeFormProp(data.name, msg, data.index);
    }

    changeFormProp(name: string, data: any, index: number) {
        let newForm = this.state.form;

        if(name === "title"){
            newForm[index].title = data as string;
        } else if(name === "description"){
            newForm[index].description = data as string;
        } else  if(name === "position"){
            newForm[index].position = Number(data);
        }  

        this.setState({ form: newForm });
    }

    onFormSelectChanged = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.changeFormProp(data.name, data.value, data.index);
    };

    onFormInputFileChanged = async (event: any, index: any) => {
        var files = event.target.files;

     


        await this.readFileDataAsBase64(files[0]).then((result) => {

            let newForm = this.state.form;
            if(newForm[index].image == undefined){

                let newImage: ObjectImage = {
                    id:-1,
                    imgUrl:"",
                    thumbnail:"",
                    title:"",
                }

                newForm[index].image = newImage;
            }
            
            newForm[index].image.fileContent = result as string;
            newForm[index].image.fileName = files[0].name;
            newForm[index].image.imgUrl = URL.createObjectURL(files[0]);

            this.setState({ form: newForm });

        });
    }

    addNewContent(){
        let newForm = this.state.form;

        let newContent: PageContent = {
            id:-1,
            order: 0,
            title:"",
            description:"",
            image: initialObjectImage,
            position: PositionContentType.LeftImageRightText,
            pageType: PageType.Home,
        }

        newForm.push(newContent);
        this.setState({ form: newForm });
    }


    cancel() {
        this.setState({loading: true, loadingMessage: "Loading Save ...", confirmCancel: false});

        let requestId = this.state.form[this.state.index].id;

        ApiRequest.post("/api/home/getbyid",{id:requestId}).then(r => {
            let newForm = this.state.form;
            newForm[this.state.index] = r.data as PageContent;
            this.setState({ form: newForm, loading: false });
            toast.warning("Данните са върнати!");

        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    save() {
        if (this.state.form[this.state.index].id > 0) {
            this.edit();
        } else {
            this.create();
        }
    }

    create() {
        this.setState({loading: true, loadingMessage: "Loading Create ...", confirmSave: false});

        ApiRequest.post("/api/home/create",this.state.form[this.state.index]).then(r => {
            let newForm = this.state.form;
            newForm[this.state.index] = r.data as PageContent;
    
            this.setState({ form: newForm, loading: false });
            toast.success("Данните са запазени!");
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    edit() {
        this.setState({loading: true, loadingMessage: "Loading Edit ...", confirmSave: false});

        ApiRequest.post("/api/home/update",this.state.form[this.state.index]).then(r => {
            let newForm = this.state.form;
            newForm[this.state.index] = r.data as PageContent;
    
            this.setState({ form: newForm, loading: false});
            toast.success("Данните са запазени!");
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    delete() {
        this.setState({loading: true, loadingMessage: "Loading Delete ...", confirmDelete: false});

        if (this.state.form[this.state.index].id > 0) {
            ApiRequest.delete("/api/home/"+ this.state.form[this.state.index].id.toString()).then(r => {
               if(r.successful){
                    let newForm = this.state.form;
                    newForm.splice(this.state.index,1);
                    this.setState({ form: newForm });
                    toast.warning("Данните са Изтрити!");
               } else {
                    toast.error("Внимание! Данните не са Изтрити!");
               }
               this.setState({loading: false, loadingMessage: ""});
            }).catch(r => {
                toast.error("Внимание! Данните не са Изтрити!");
                this.setState({loading: false, loadingMessage: ""});
            })
        } else {
            let newForm = this.state.form;
            newForm.splice(this.state.index,1);
            this.setState({ form: newForm });
            toast.warning("Данните са Изтрити!");
            this.setState({loading: false});
        }
        
    }

    renderContent(content: PageContent, index: any){
       if (content != undefined) {
           return  this.renderLeftImageRightText(content, index);
       }
        
    }



    renderLeftImageRightText(content: PageContent, indexObj: any){
        return (
        <Segment key={indexObj}>
            <Grid  centered>
                <Grid.Row columns={2} style={{fontSize:"large", margin:"auto"}}>
                    <Grid.Column width={this.state.isMobile ? 16 : 6} style={{float:"right", margin:"auto"}}>
                                <Grid.Row style={{ margin:"auto"}}>
                                    {content.image != undefined && 
                                        <Image style={{width:"auto", flex:"auto"}} src={content.image.imgUrl != "" ? content.image.imgUrl : ""} ui={true} fluid />
                                    }
                                </Grid.Row>
                                <Grid.Row style={{ margin:"auto"}}>
                                   <Grid.Column style={{ margin:"auto"}}>
                                    <Button compact style={{width:"min-content", height:"40px"}}>
                                        <label htmlFor={`files-${indexObj}`}  >Прикачи
                                            <input hidden
                                                onChange={(event) =>this.onFormInputFileChanged(event, indexObj)}
                                                accept=".jpg"
                                                type="file"
                                                id={`files-${indexObj}`} 
                                                style={{ visibility: "hidden" }}
                                                key={`input-${indexObj}`} 
                                            />
                                        </label>
                                    </Button>
                                    </Grid.Column>
                                    <Grid.Column>

                                    <Form.Select 
                                        name="position"
                                        index={indexObj} 
                                        options={positionOptions}
                                        value={content.position.toString()}
                                        onChange={this.onFormSelectChanged}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                          
                    </Grid.Column>
                   
                    <Grid.Column width={this.state.isMobile ? 16 : 10} >
                        <React.Fragment key={indexObj} >
                            <Form.Group widths="16">
                                <Form.Field width={16}>
                                    <label>Заглавна част</label>
                                    <Form.Input
                                        index={indexObj} 
                                        key={`t-${indexObj}`} 
                                        name="title"
                                        value={content.title}
                                        onChange={this.onFormInputChanged}
                                        />
                                </Form.Field>
                            </Form.Group >
                            <Form.Group >
                                <Form.Field width={16}>
                                    <label>Съдържание</label>
                                    <Form.TextArea 
                                        index={indexObj} 
                                        key={`d-${indexObj}`} 
                                        name="description"
                                        value={content.description}
                                        onChange={this.onFormTextAreaChanged}
                                        />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group style={{float:"right", margin:"auto"}}>
                            <Grid.Row >
                                 <Button icon="trash alternate" primary onClick={() => this.setState({ confirmDelete: true, index: indexObj })} />
                                <Button icon="check" positive onClick={() => this.setState({ confirmSave: true, index: indexObj })} />
                                <Button icon="cancel" negative onClick={() => this.setState({ confirmCancel: true, index: indexObj })} />
                                </Grid.Row>
                            </Form.Group>
                        
                            </React.Fragment>
                    </Grid.Column>
                </Grid.Row>
             
            </Grid>
        </Segment>
        )
    }


    renderEditorActions() {

        return (
                <Form>
                    <Grid centered padded divided>
                            <Grid.Row>
                                <Button primary content="Нов раздел" onClick={() => this.addNewContent()} />
                                <Button secondary content="Откажи" onClick={() => this.navigate("/home")} />
                            </Grid.Row>
                    </Grid>
                    <div>  </div>
                </Form>
                
        );

    }

    renderConfirm(){
        return (
            <>
            <Confirm
                content="Потвърдете Отказ?"
                open={this.state.confirmCancel}
                onCancel={() => this.setState({ confirmCancel: false })}
                onConfirm={() => this.cancel()}
                cancelButton="No"
                confirmButton="Yes"
            />

            <Confirm
                content="Потвърдете Запис?"
                open={this.state.confirmSave}
                onCancel={() => this.setState({ confirmSave: false })}
                onConfirm={() => this.save()}
                cancelButton="No"
                confirmButton="Yes"
            />

            <Confirm
                content="Потвърдете Изтриване?"
                open={this.state.confirmDelete}
                onCancel={() => this.setState({ confirmDelete: false })}
                onConfirm={() => this.delete()}
                cancelButton="No"
                confirmButton="Yes"
            />

            </>
        )
    }
 
    render() {
        return (
            <>
                <SegmentedHeader content="За нас">
                    <h2>За Нас</h2>
                    <div style={{fontStyle: 'italic'}}>
                    <h3 >Ние сбъдваме мечтата за Вашият Дом</h3>
                    </div>
                  </SegmentedHeader>
                   


                    <Form>
                        {this.state.form != undefined &&
                            this.state.form.map((map, index) => {
                                return (
                                    this.renderContent(map, index)
                                )      
                            })
                        }
                    </Form>

                    {this.renderEditorActions()}       

                    <Dimmer active={this.state.loading} inverted page>
                        <Loader content={this.state.loadingMessage} />
                    </Dimmer>

                    {this.renderConfirm()}


            </>
        );

}

    


}
    
export const HomeEditorPage = HomeEditorPageImpl;