export interface ObjectInfo {
    id: number;
    order: number;
    title: string;
    description: string;
    objectImageItems: ObjectImage[];
}

export interface ObjectImage {
    id: number;
    imgUrl: string;
    thumbnail: string;
    title?: string;
    fileName?: string;
    fileContent?: string;
}

export interface LoginDto {
    email: string;
    password: string;
}

export interface PricingPlan {
    id: number;
    order: number;
    type: PriceType;
    title: string;
    currency: string;
    amount: string;
    amountType: string;
    descriptions: string[];
    pageContents: PageContent[];
}

export interface PageContent {
    id: number ;
    order: number;
    title: string;
    description: string;
    image: ObjectImage;
    position: PositionContentType;
    pageType: PageType;
}


export interface IFileInfoRequest{
    content : string,
    name: string
}

export enum UserType {
    Error = 0,
    Administrator = 1,
    Guest = 2,
}

export enum PriceType {
    Error = 0,
    Warning = 1,
    Active = 2,
    Danger = 3
}

export enum PositionContentType
{
    LeftImageRightText = 0,
    RightImageLeftText = 1,
    TopImageBottomText = 2,
    BottomImageTopText = 3,
}

export enum PageType
{
    None = 0,
    Home = 1,
    Object = 2,
    Price = 3,
    Contact = 4,
}

export const initialObjectImage: ObjectImage = {
    id:-1,
    imgUrl:"",
    thumbnail:"",
    title:"",
}

export const initialObjectInfo: ObjectInfo = {
    id:0,
    title:"",
    description:"",
    objectImageItems:[],
    order:0,
};

export const initialPageContent: PageContent = {
    id:0,
    order: 0,
    title:"",
    description:"",
    image: initialObjectImage,
    position: PositionContentType.LeftImageRightText,
    pageType: PageType.Home,
};

export function enumValueToKeys<T>(v: any): T {
    const result = {} as any;
    for(let k in v) {
        const vk = v[k];
        result[vk] = "";
    }

    return result as T;
}


export function defaultChangeEventData<T>(formItems: T, data: IInputDataProps): ISingleForm<T>|null {
    const name = data.name;
    if(name == null) {
        return null;
    }
    const value = data.value;
    if(value == null) {
        return null;
    }
    const result = {
        form: { ...formItems, [name]: value}
    };

    return result as ISingleForm<T> | null
}



interface IInputDataProps {
    name?: string;
    value?: string|number;
}

interface ISingleForm<T> {
    form: T;
}

export interface MetaContainerDto {
    pageInfo: PageInfoDto;
    info: any;
}

export interface PageInfoDto {
    totalCount: number;
    pageCount: number;
    resultCount: number;
}
