import * as React from "react";
import { BasePage } from "../BasePage";
import { RouteComponentProps } from "react-router-dom";
import { SegmentedHeader } from "../../components/Shared/SegmentedHeader";
import { Dimmer, Grid, Loader, Image, Segment, Form, InputOnChangeData, TextAreaProps, Confirm, Button, Select, DropdownProps, GridColumn, Container } from "semantic-ui-react";
import { MyMapComponent } from "../../components/Shared/GoogleMap";
import { UploadFile } from "../../components/Shared/UploadFile";
import { ApiRequest } from "../../components/Shared/Network/RequestHelper";
import { initialObjectImage, initialObjectInfo, initialPageContent, ObjectImage, ObjectInfo, PageContent, PageType, PositionContentType } from "../../Typedefs.gen";
import { Session } from "../../components/Shared/Network/Session";
import { FormEvent } from "react";
import { toast } from "react-toastify";

enum Mode {
    Create,
    Edit
}

const positionOptions = [
    { key: '0', value: '0', text: 'Снимка-Ляво Текст-Дясно' },
    { key: '1', value: '1', text: 'Снимка-Дясно Текст-Ляво' },
    { key: '2', value: '2', text: 'Снимка-Горе Текст-Долу' },
    { key: '3', value: '3', text: 'Снимка-Долу Текст-Горе' }
]


interface IProps {
}

interface IState {
    loading: boolean;
    loadingMessage: string;
    confirmCancel: boolean;
    confirmSave: boolean;
    confirmDelete: boolean;
    confirmObjectDelete: boolean;
    form: ObjectInfo;
    mode: Mode;
    objectId: number;
    index: number;
    isMobile: boolean;
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class ObjectEditorPageImpl extends BasePage<TPropsImpl, TStateImpl> {

    constructor(props: TPropsImpl) {
        super(props);


    let fileInputRef= React.createRef();

        var idNum = parseInt(props.match.params.id as string);
        var id = isNaN(idNum) ? -1 : idNum;
        var mode = (id == -1) ? Mode.Create : Mode.Edit;


        this.state = {
            loading: false,
            loadingMessage: "",
            confirmCancel: false,
            confirmSave: false,
            confirmDelete: false,
            confirmObjectDelete: false,
            form: initialObjectInfo,
            mode: mode,
            objectId: id,
            index: id,
            isMobile: document.documentElement.clientWidth < 769 ? true : false,

        };

    }

    componentDidMount() {
        if(!Session.hasAuthKey()){
            this.navigate("/home")
        }

        window.addEventListener('resize', this.handleWindowSizeChange);

        this.setDocumentTitle("Нашите обекти - редактиране");
        if(this.state.objectId>0){
            this.loadData();
        } else {

        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        var currentWidth = document.documentElement.clientWidth < 500 ? true : false;;
        if (currentWidth != this.state.isMobile) {
            this.setState({isMobile: currentWidth})
        }
    };

    loadData(){
        this.setState({loading: true, loadingMessage: "Loading object ..."});

        ApiRequest.post("/api/objects/GetById",{id: this.state.objectId}).then(r => {
            let data: ObjectInfo = r.data as ObjectInfo;
            this.setState({form: data, loading: false, loadingMessage: ""});
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })


    }

    navigate(link: string) {
        this.props.history.push(link);
    }



    onFormInputChanged = (event: any, data: InputOnChangeData) => {
        this.changeFormProp(data.name, data.value);
    }

    onFormTextAreaChanged = (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        let msg = data.value ?  data.value.toString() : "";
        this.changeFormProp(data.name, msg);
    }

    changeFormProp(name: string, data: any) {
        var newForm = { ...this.state.form, [name]: data };
        this.setState({ form: newForm });
    }


    onFormInputFileChanged = async (event: any, index: any) => {
        var files = event.target.files;

        await this.readFileDataAsBase64(files[0]).then((result) => {

            let newForm = this.state.form;
            
            newForm.objectImageItems[index].fileContent = result as string;
            newForm.objectImageItems[index].fileName = files[0].name;
            newForm.objectImageItems[index].imgUrl = URL.createObjectURL(files[0]);

            this.setState({ form: newForm });
        });
    }

    addNewContent(){
        let newForm = this.state.form;

        let newImage: ObjectImage = {
            id:-1,
            imgUrl: "",
            thumbnail:"",
            title:"",
            fileName:"",
            fileContent:"",
        }

        newForm.objectImageItems.push(newImage);
        this.setState({ form: newForm });
    }

    cancel() {
        this.navigate("/admin/objects");
    }

    save() {
        if (this.state.form.id > 0) {
            this.edit();
        } else {
            this.create();
        }
    }

    create() {
        this.setState({loading: true, loadingMessage: "Loading Create ...", confirmSave: false});

        ApiRequest.post("/api/objects/create",this.state.form).then(r => {
            const data = r.data as any;
            if(data != undefined){
                this.navigate("/admin/objects");
                toast.success("Данните са запазени!");
                this.setState({loading: false, loadingMessage: ""});
            } else {
                toast.error("Възникна проблем! Данните не са запазени!");
                this.setState({loading: false, loadingMessage: ""});
            }
        }).catch(r => {
            toast.error("Възникна проблем! Данните не са запазени!");
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    edit() {
        this.setState({loading: true, loadingMessage: "Loading Edit ...", confirmSave: false});

        ApiRequest.post("/api/objects/update", this.state.form).then(r => {
            const data = r.data as any;
            if(data != undefined ){
                this.navigate("/admin/objects");
                toast.success("Данните са запазени!");
                this.setState({loading: false, loadingMessage: ""});

            } else {
                toast.error("Възникна проблем! Данните не са запазени!");
                this.setState({loading: false, loadingMessage: ""});
            }
        }).catch(r => {
            toast.error("Възникна проблем! Данните не са запазени!");
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    deleteImage(){
        let newForm = this.state.form;
        newForm.objectImageItems.splice(this.state.index,1);
        this.setState({form: newForm, confirmDelete: false});
    }
   
    deleteObject(){
        this.setState({loading: true, loadingMessage: "Loading Delete object ...", confirmObjectDelete: false});

        ApiRequest.delete("/api/objects/" + this.state.form.id.toString()).then(r => {
           if(r.successful){
                this.navigate("/admin/objects");
                toast.success("Данните са изтрити!");
                this.setState({loading: false});
            } else {
                toast.error("Възникна проблем! Данните не са изтрити!");
            }
        }).catch(r => {
            toast.error("Възникна проблем! Данните не са Изтрити!");
            this.setState({loading: false, loadingMessage: ""});
        })
        
    }

    renderTitleObject(){
        return (
            <Form>
                <Segment key={this.state.objectId}>
                    <React.Fragment key={this.state.objectId} >
                        <Form.Group widths="16">
                            <Form.Field width={16}>
                                <label>Заглавна част</label>
                                <Form.Input
                                    name="title"
                                    value={this.state.form.title}
                                    onChange={this.onFormInputChanged}
                                    />
                            </Form.Field>
                        </Form.Group >
                        <Form.Group >
                            <Form.Field width={16}>
                                <label>Описание на обекта</label>
                                <Form.TextArea 
                                    name="description"
                                    value={this.state.form.description}
                                    onChange={this.onFormTextAreaChanged}
                                    />
                            </Form.Field>
                        </Form.Group>
                    </React.Fragment>
                </Segment>
            </Form>    
        )
    }

    renderImage(imageContent: ObjectImage, index: number){
        return(
            <Grid.Column  key={`gic-${index}`} style={{width:"23rem", height:"23rem", margin:"auto"}}>
                <Grid.Row style={{placeContent:"center", height:"85%",     border: "ridge"}}> 
                    {imageContent.imgUrl != "" &&
                        <div className="small image" style={{height:"100%", background:"center"}}>
                            <img src={imageContent.imgUrl != "" ? imageContent.imgUrl : ""} style={{height:"100%", width:"100%", objectFit: "contain"}}/>
                         </div>
                    //  <Image src={imageContent.imgUrl != "" ? imageContent.imgUrl : ""} ui={true} style={{maxHeight:"250px", placeContent:"center"}}/>
                    }
                    {imageContent.imgUrl == "" &&
                     <Container style={{height:"200px", flex:"auto"}}>  </Container>
                    }
                </Grid.Row>
                <Grid.Row style={{placeContent:"center",  height:"15%"}}>
                    <Button  as="label" icon="upload" positive  htmlFor={`files-${index}`} />
                            <input hidden
                                onChange={(event) =>this.onFormInputFileChanged(event, index)}
                                accept=".jpg"
                                type="file"
                                id={`files-${index}`} 
                                style={{ visibility: "hidden" }}
                                key={`input-${index}`} 
                            />
                    <Button icon="trash alternate" primary onClick={() => this.setState({ confirmDelete: true, index: index })} />
                </Grid.Row>
            </Grid.Column>
        )
    }


    renderEditorActions() {

        return (
            <Grid centered padded>
                <Grid.Row>
                    <Button primary content="Добави нова снимка" onClick={() =>this.addNewContent()} />
                </Grid.Row>    
                <Grid.Row>
                    <Button positive content="Запази" onClick={() =>this.setState({confirmSave: true})} />
                    <Button secondary content="Откажи" onClick={() =>this.setState({confirmCancel: true})} />
                </Grid.Row>
                    <Grid.Row>
                        <Button negative icon="trash" content="Изтриване на обекта" onClick={() => this.setState({confirmObjectDelete: true})} />
                    </Grid.Row>
            </Grid>
        );

    }

    renderConfirm(){
        return (
            <>
            <Confirm
                content="Потвърдете Отказ?"
                open={this.state.confirmCancel}
                onCancel={() => this.setState({ confirmCancel: false })}
                onConfirm={() => this.cancel()}
                cancelButton="No"
                confirmButton="Yes"
            />

            <Confirm
                content="Потвърдете Запис?"
                open={this.state.confirmSave}
                onCancel={() => this.setState({ confirmSave: false })}
                onConfirm={() => this.save()}
                cancelButton="No"
                confirmButton="Yes"
            />

            <Confirm
                content="Потвърдете Изтриване?"
                open={this.state.confirmDelete}
                onCancel={() => this.setState({ confirmDelete: false })}
                onConfirm={() => this.deleteImage()}
                cancelButton="No"
                confirmButton="Yes"
            />

            <Confirm
                content="Потвърдете Изтриване на целия обект?"
                open={this.state.confirmObjectDelete}
                onCancel={() => this.setState({ confirmObjectDelete: false })}
                onConfirm={() => this.deleteObject()}
                cancelButton="No"
                confirmButton="Yes"
            />
            </>
        )
    }
 
    render() {
        return (
            <>
                <SegmentedHeader content="Преглед на обект" />
                   
               
                {this.renderTitleObject()}  

                <Grid padded columns={3} >
                    {this.state.form.objectImageItems != undefined &&
                        this.state.form.objectImageItems.map((image, index) => {
                            return ( this.renderImage(image, index))
                        })
                    }
                </Grid>
               
                {this.renderEditorActions()}       
                {this.renderConfirm()}

                <Dimmer active={this.state.loading} inverted page>
                     <Loader content={this.state.loadingMessage} />
                </Dimmer>
            </>
        );

}

    


}
    
export const ObjectEditorPage = ObjectEditorPageImpl;