import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { Protocol, RequestType } from "./HttpHelper";
import { TApiResponse, IApiSuccess, IApiMeta, makeError, IApiError, TApiSuccess, TApiError } from "./ApiHelper";

export  interface IResponseMeta<T = undefined> {
    axios: IAxiosMeta;
    remote: T;
}

interface IAxiosMeta {
    status: number;
    headers: any;
    config: any;
}

const settings = require("../../../local.config");


// export const __Protocol__ = JSON.stringify(settings.Protocol);
// export const __Host__ = JSON.stringify(settings.Host);
// export const __Port__ = JSON.stringify(settings.Port);

class ApiRequestImpl {
    baseApiPath: string;
    requestConfig: object;

    constructor(hostname: string, port: number = 80, postfix = "", protocol: Protocol = Protocol.Https) {
        const portPart = port == 80 ? "" : ":" + port;
        let protocolType = protocol as string;
        if(protocol === Protocol.Auto) {
            const href = window.location.href;
            protocolType = href.substring(0, href.indexOf("://"));
        }

        let protocolPath = "";
        if(protocol !== Protocol.None) {
            protocolPath = protocolType + "://";
        }
        // this.baseApiPath = protocolPath + hostname + portPart + postfix;
        this.baseApiPath = protocolPath + hostname  + postfix;

        this.requestConfig = {
            headers: {
            },
            withCredentials: true
        };
    }

    get<T, M = undefined>(route: string, params?: any) {
        return this.safeRequest<T, M>(RequestType.Get, route, params);
    }

    post<T, M = undefined>(route: string, data?: any) {
        return this.safeRequest<T, M>(RequestType.Post, route, data);
    }

    put<T, M = undefined>(route: string, data?: any) {
        return this.safeRequest<T, M>(RequestType.Put, route, data);
    }

    patch<T, M = undefined>(route: string, data?: any) {
        return this.safeRequest<T, M>(RequestType.Patch, route, data);
    }

    delete<T, M = undefined>(route: string, data?: any) {
        return this.safeRequest<T, M>(RequestType.Delete, route, data);
    }
    
    private safeRequest<T, M = undefined>(requestType: RequestType, route: string, data?: any): Promise<TApiSuccess<T, IResponseMeta<M>>>  {
        if(!route.startsWith("/")) {
            throw new Error("No starting / in route")
        }
        let requestMethod = requestType as string;

        //const requestUrl = this.baseApiPath + route;
        const requestUrl = route;

        const paramsToMethod: any[] = [requestUrl];

        const requestConfig = {headers: {}, withCredentials: true};
        if(requestType == RequestType.Delete) {
            (requestConfig as any).data = data;
        } else if(requestType == RequestType.Get) {
            (requestConfig as any).params = data;
        } else {
            paramsToMethod.push(data);
        }

        paramsToMethod.push(requestConfig);

        return new Promise((res, rej) => {
            //debugger;
            (axios as any)[requestMethod].apply(axios, paramsToMethod)
                .then((r: AxiosResponse<TApiResponse<T>>) => {
                    res(this.transformFromSuccess(r));
                })
                .catch((e: AxiosError) => {
                    rej(this.transformError(e));
                })
        });
    }

    private transformFromSuccess<T, M = undefined>(response: AxiosResponse<TApiResponse<T>>): TApiSuccess<T, IResponseMeta<M>> {
        const responseResult = response as any as AxiosResponse<IApiSuccess<T> & IApiMeta<any>>;
        const meta = {
            axios: {
                status: response.status,
                config: response.config,
                headers: response.headers,
            }
        } as any;

        if(responseResult.data.meta != null) {
            meta.remote = responseResult.data.meta;
        }

        let result: any = {
            meta, data: responseResult.data.data, successful: responseResult.data.successful,
        };
        return result;
    }

    private transformError(axiosError: AxiosError): TApiError<IResponseMeta> {
        let status = 501;
        let result: IApiError & IApiMeta<IResponseMeta>|undefined;
        const meta: Partial<IResponseMeta> = {};
        const response = axiosError.response;
        const request = axiosError.request;
        if(response !== undefined) {
            status = response.status;
            
            meta.axios = {
                status,
                config: axiosError.config,
                headers: response.headers
            }

            const data = response.data;
            if(data !== undefined && data.error !== undefined) {
                result = makeError(data.error.title, data.error.details, meta) as any;
            } else {
                result = makeError(axiosError.name, axiosError.message, meta) as any;
            }
          
        } else if(request !== undefined) {
            meta.axios = {
                status,
                config: axiosError.config,
                headers: {}
            };

            result = makeError(axiosError.name, axiosError.message, meta) as any;
        }
        else {
            meta.axios = {status, config: {}, headers: {}};
            result = makeError(axiosError.name, axiosError.message, meta) as any;
        }

        return result!;
    }
}

const __Host__ = JSON.stringify(settings.Host).substring(1, JSON.stringify(settings.Host).length-1);
const __Port__ = settings.Port;
const __Protocol__ = JSON.stringify(settings.Protocol).substring(1, JSON.stringify(settings.Protocol).length-1);;


export const ApiRequest = new ApiRequestImpl(__Host__, __Port__, ``, __Protocol__ as any);
