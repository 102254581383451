import React, { Component } from 'react';
import { Route } from 'react-router';


import './custom.scss'
import { ContactsPage } from './Pages/ContactsPage';
import { PricesPage } from './Pages/PricesPage';
import { Layout } from './components/Layout';
import { HomePage } from './Pages/HomePage';
import { ObjectsPage } from './Pages/ObjectsPage';
import { ObjectPage } from './Pages/ObjectPage';
import { LoginPage } from './Pages/LoginPage';
import { PricePage } from './Pages/PricePage';
import { Redirect } from 'react-router-dom';
import { HomeEditorPage } from './Pages/Editor/HomeEditPage';
import { ObjectEditorPage } from './Pages/Editor/ObjectEditPage';
import { ObjectsEditPage } from './Pages/Editor/ObjectsEditPage';
import { PriceEditPage } from './Pages/Editor/PriceEditPage';
import { UsersPage } from './Pages/UsersPage';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/'>
          <Redirect to="/home" />
        </Route>
        <Route path='/home' component={HomePage} />
        <Route path='/objects' component={ObjectsPage} />
        <Route path='/admin/objects' component={ObjectsEditPage} />
        <Route path='/object/:id?' component={ObjectPage} />
        <Route path='/admin/object/:id?' component={ObjectEditorPage} />
        <Route path='/prices' component={PricesPage} />
        <Route path='/admin/prices' component={PricesPage} />
        <Route path='/price/:id?' component={PricePage} />
        <Route path='/admin/price/:id?' component={PriceEditPage} />
        <Route path='/contacts' component={ContactsPage} />
        <Route path='/login' component={LoginPage} />

        <Route path='/admin/home' component={HomeEditorPage} />
        <Route path='/admin/users' component={UsersPage} />

      </Layout>
    );
  }
}
