import * as React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { isPropertySignature } from 'typescript';

export const MyMapComponent = withScriptjs(withGoogleMap((props: any) =>
  <GoogleMap
    defaultZoom={11}
    defaultCenter={{ lat: props.cordinateLat, lng: props.cordinateLng}}
  >
    {props.isMarkerShown && 
        <Marker position={{ lat: props.cordinateLat, lng: props.cordinateLng }} 
                title={props.title}
        />}
  </GoogleMap>
))
