import * as React from "react";
import { BasePage } from "./BasePage";
import { RouteComponentProps } from "react-router-dom";
import { SegmentedHeader } from "../components/Shared/SegmentedHeader";
import { Dimmer, Grid, Loader, Image, Segment } from "semantic-ui-react";
import { MyMapComponent } from "../components/Shared/GoogleMap";
import { UploadFile } from "../components/Shared/UploadFile";
import { ApiRequest } from "../components/Shared/Network/RequestHelper";
import { PageContent, PageType, PositionContentType } from "../Typedefs.gen";

interface IProps {
}

interface IState {
    loading: boolean;
    loadingMessage: string;
    form: PageContent[];
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class UsersPageImpl extends BasePage<TPropsImpl, TStateImpl> {
  
    constructor(props: TPropsImpl) {
        super(props);

        this.state = {
            loading: false,
            loadingMessage: "",
            form: [],
        };

    }

    componentDidMount() {
        this.setDocumentTitle("Потребители");
        this.loadData();
    }

    loadData(){
        this.setState({loading: true, loadingMessage: "Loading objects ..."});

        ApiRequest.post("/api/home/GetByType",{PageType: PageType.Home}).then(r => {
            let data: PageContent[] = r.data as PageContent[];
            this.setState({form: data, loading: false, loadingMessage: ""});
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }


    

 
    render() {
        return (
            <>

                <SegmentedHeader content="Потребители" >
                    <h2>Потребители</h2>
                    

                  </SegmentedHeader>
                   
                    <Grid >
                        <Grid.Column>
                        
                        </Grid.Column>

                    </Grid>

                     <Dimmer active={this.state.loading} inverted page>
                        <Loader content={this.state.loadingMessage} />
                    </Dimmer>
            </>
        );

}

    


}
    
export const UsersPage = UsersPageImpl;