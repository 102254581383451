import * as React from "react";
import { RouteComponentProps } from "react-router";
import { BasePage } from "./BasePage";
import { Dimmer, Grid, Image, Loader, Segment } from "semantic-ui-react";
import { SegmentedHeader } from "../components/Shared/SegmentedHeader";
import { ApiRequest } from "../components/Shared/Network/RequestHelper";
import { ObjectImage, PageContent, PriceType, PricingPlan } from "../Typedefs.gen";
import { Session } from "../components/Shared/Network/Session";

const initPricingPlan: PricingPlan = {
    id: -1,
    type: PriceType.Error,
    title: "",
    currency: "",
    amount: "",
    amountType: "",
    descriptions: [],
    pageContents: [],
    order:0,
}

interface IProps {
}

interface IState {
    id: number;
    form: PricingPlan;
    priceDescription: any;
    uploadFileLoading: boolean;
    loading: boolean;
    loadingMessage: string;
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class PricePageImpl extends BasePage<TPropsImpl, TStateImpl> {
  
    constructor(props: TPropsImpl) {
        super(props);

        var idNum = parseInt(props.match.params.id as string);
        var id = isNaN(idNum) ? -1 : idNum;
        
        this.state = {
            id: id,
            form: initPricingPlan,
            priceDescription: "",
            uploadFileLoading: false,
            loading: false,
            loadingMessage: "",
        };

    }

    componentDidMount() {
        this.setDocumentTitle("Prices");
        this.loadData();
    }

    loadData() {
        this.setState({loading: true, loadingMessage: "Loading PricingPlan ..."});

        ApiRequest.post("/api/PricingPlan/GetById", {id: this.state.id}).then(r => {
            let data: PricingPlan = r.data as PricingPlan;
            this.setState({form: data, loading: false, loadingMessage: ""});
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })


    }

    navigate(link: string) {
        this.props.history.push(link);
    }

    renderImage(item: ObjectImage) {
        return (
            <Grid divided='vertically' key={item.id} centered>
                <Grid.Row>
                    <Image style={{width:"auto", flex:"auto"}} src={item.imgUrl != "" ? item.imgUrl : ""} ui={true} fluid />
                </Grid.Row>
            </Grid>
        )
    }

    renderLeft(element: PageContent){
        return (     
            <Grid.Row key={element.id} style={{fontSize:"large"}}>
                {element.image != null &&
                    <Grid.Column width={6}>
                        {this.renderImage(element.image)}
                    </Grid.Column>
                }
                <Grid.Column width={10} style={{overflowWrap: 'break-word', alignSelf: "center", textAlignLast:"center"}}>
                    <div>
                        <h2 style={{ textAlign: "left", fontStyle: 'italic'}}>{element.title}</h2>
                        <div>{element.description}</div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        )
    }

    renderRight(element: PageContent){
        return (     
            <Grid.Row key={element.id}  style={{fontSize:"large"}}>
                <Grid.Column width={10} style={{overflowWrap: 'break-word', alignSelf: "center", textAlignLast:"center"}}>
                    <div>
                        <h2 style={{ textAlign: "left", fontStyle: 'italic'}}>{element.title}</h2>
                        <div>{element.description}</div>
                    </div>
                </Grid.Column>
                {element.image != undefined &&
                    <Grid.Column width={6}>
                        {this.renderImage(element.image)}
                    </Grid.Column>
                }
            </Grid.Row>

        )
    }

    render() {
        return (
            <>
                <SegmentedHeader content={this.state.form.title}/>
                <Grid container centered >
                        { this.state.form.pageContents != undefined &&
                             this.state.form.pageContents.map( (element, index) => { 
                                 if(index % 2 == 0){
                                    return this.renderLeft(element);
                                 }
                                 else 
                                 {
                                     return this.renderRight(element);
                                 }
                            })
                        }   
                </Grid>
                
                <Dimmer active={this.state.loading} inverted page>
                    <Loader content={this.state.loadingMessage} />
                </Dimmer>

            </>);
    }



}

export const PricePage = PricePageImpl;