import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dimmer, Grid, Loader, Segment } from "semantic-ui-react";
import { ApiRequest } from "../../components/Shared/Network/RequestHelper";
import PhotoBox from "../../components/Shared/PhotoBox";
import { SegmentedHeader } from "../../components/Shared/SegmentedHeader";
import { ObjectImage, ObjectInfo } from "../../Typedefs.gen";
import { BasePage } from "../BasePage";

interface IProps {
}


interface IState {
    products: ObjectInfo[];
    loading: boolean;
    loadingMessage: string;
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class ObjectsEditPageImpl extends BasePage<TPropsImpl, TStateImpl> {
  
    constructor(props: TPropsImpl) {
        super(props);
        
        this.state = {
            products:[],
            loading: true,
            loadingMessage: "",
        };

    }

    componentDidMount() {
        this.setDocumentTitle("Home");
        this.loadData();
    }


    loadData(){
        this.setState({loading: true, loadingMessage: "Loading objects ..."});

        ApiRequest.get("/api/objects/All").then(r => {
            let data: ObjectInfo[] = r.data as ObjectInfo[];
            this.setState({products: data, loading: false, loadingMessage: ""});

        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    navigate(link: string) {
        this.props.history.push(link);
    }

    render() {


        return (
            <>
                <SegmentedHeader content="Нашите обекти - Редактиране"/>
                <Grid centered>
                    <Grid.Row>
                        <Button primary content="Добави нов обект" onClick={() => this.navigate("/admin/object")} />
                    </Grid.Row>
                </Grid>

                <Grid doubling columns={3} style={{textAlign: "-webkit-center"}} >
                    {this.state.products.map(item => {
                        return (
                            <Grid.Column key={item.id}>
                                {item != undefined && 
                                  PhotoBox(item.objectImageItems[0]?.imgUrl, item.title, item.id, this.props)}
                            </Grid.Column>
                         
                        )
                    })}

                </Grid>

                <Dimmer active={this.state.loading} inverted page> 
                    <Loader content={this.state.loadingMessage} />
                </Dimmer>

            </>
        );

}

    


}

export const ObjectsEditPage = ObjectsEditPageImpl;