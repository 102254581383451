import * as React from "react"
import { Grid } from "semantic-ui-react";

interface IProps {
    maxWidth?: number;
}

interface IState {
}

type TPropsImpl = IProps;
type TStateImpl = IState;

class CenterGridImpl extends React.Component<TPropsImpl, TStateImpl> {

    constructor(props: TPropsImpl) {
        super(props);
    }

    render() {
        const maxWidth = this.props.maxWidth || 450;
        return (
            <Grid textAlign="center" className={"form-grid"} verticalAlign="middle">
                <Grid.Column style={{ maxWidth }}>
                    {this.props.children}
                </Grid.Column>
            </Grid>
        );
    }
}

export const CenterGrid = CenterGridImpl;