import * as React from "react";
import { BasePage } from "./BasePage";
import { RouteComponentProps } from "react-router-dom";
import { SegmentedHeader } from "../components/Shared/SegmentedHeader";
import { Dimmer, Grid, Loader, Image, Segment } from "semantic-ui-react";
import { MyMapComponent } from "../components/Shared/GoogleMap";
import { UploadFile } from "../components/Shared/UploadFile";
import { ApiRequest } from "../components/Shared/Network/RequestHelper";
import { PageContent, PageType, PositionContentType } from "../Typedefs.gen";

interface IProps {
}

interface IState {
    loading: boolean;
    loadingMessage: string;
    form: PageContent[];
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class HomePageImpl extends BasePage<TPropsImpl, TStateImpl> {
  
    constructor(props: TPropsImpl) {
        super(props);

        this.state = {
            loading: false,
            loadingMessage: "",
            form: [],
        };

    }

    componentDidMount() {
        this.setDocumentTitle("За нас");
        this.loadData();
    }

    loadData(){
        this.setState({loading: true, loadingMessage: "Loading ..."});

        ApiRequest.post("/api/home/GetByType",{PageType: PageType.Home}).then(r => {
            let data: PageContent[] = r.data as PageContent[];
            this.setState({form: data, loading: false, loadingMessage: ""});
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    renderContent(content: PageContent, index: any){
        if (content.position == PositionContentType.LeftImageRightText) {
            return  this.renderLeftImageRightText(content, index);
        } else  if (content.position == PositionContentType.RightImageLeftText) {
            return this.renderRightImageLeftText(content, index);
        } else  if (content.position == PositionContentType.TopImageBottomText) {
            return this.renderTopImageBottomText(content, index);
        } else  if (content.position == PositionContentType.BottomImageTopText) {
            return this.renderBottomImageTopText(content, index);
        } else {
            return  this.renderLeftImageRightText(content, index);
        }
}

    renderLeftImageRightText(content: PageContent, index: any){
        return (
        <Grid key={index}>
           <Grid.Row style={{fontSize:"large", margin:"auto"}}>
                {content.image != undefined && content.image.imgUrl != "" &&
                    <Grid.Column width={6} style={{display:"inline-flex", alignItems: "center"}}>
                            <Image style={{width:"32rem", objectFit: "contain"}} src={content.image.imgUrl != "" ? content.image.imgUrl : ""} ui={true} fluid />
                    </Grid.Column>
                }
                <Grid.Column width={content.image != undefined ? 10 : 16} style={{overflowWrap: 'break-word', alignSelf: "center", textAlignLast:"center"}}>
                    <div>
                        <h2 style={{ textAlign: "left", fontStyle: 'italic'}}>{content.title}</h2>
                        <div>{content.description}</div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        )
    }

    renderRightImageLeftText(content: PageContent, index: any){
        return (
            <Grid key={index}>
                <Grid.Row style={{fontSize:"large", margin:"auto"}} key={index}>
                        <Grid.Column width={content.image != undefined ? 10 : 16} style={{overflowWrap: 'break-word', alignSelf: "center", textAlignLast:"center"}}>
                            <div>
                                <h2 style={{ textAlign: "left", fontStyle: 'italic'}}>{content.title}</h2>
                                <div>{content.description}</div>
                            </div>
                        </Grid.Column>
                        {content.image != undefined && content.image.imgUrl != "" &&
                            <Grid.Column width={6} style={{display:"inline-flex", alignItems: "center"}}>
                                    <Image style={{width:"32rem", objectFit: "contain"}} src={content.image.imgUrl != "" ? content.image.imgUrl : ""} ui={true} fluid />
                            </Grid.Column>
                        }
                    </Grid.Row>
            </Grid>
        )
    }

    renderTopImageBottomText(content: PageContent, index: any){
        return (
            <Grid key={index}>
                <Grid.Row style={{fontSize:"large", margin:"auto"}}>
                    {content.image != undefined &&
                        <Grid.Column>
                                <Image style={{width:"auto", flex:"auto"}} src={content.image.imgUrl != "" ? content.image.imgUrl : ""} ui={true} fluid />
                        </Grid.Column>
                    }
                </Grid.Row>
                <Grid.Row style={{margin:"auto"}}>    
                    <Grid.Column style={{overflowWrap: 'break-word', alignSelf: "center", textAlignLast:"center"}}>
                        <div>
                            <h2 style={{ textAlign: "left", fontStyle: 'italic'}}>{content.title}</h2>
                            <div>{content.description}</div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    renderBottomImageTopText(content: PageContent, index: any){
        return (
            <Grid key={index}>
                <Grid.Row style={{margin:"auto"}}>    
                    <Grid.Column style={{overflowWrap: 'break-word', alignSelf: "center", textAlignLast:"center"}}>
                        <div>
                            <h2 style={{ textAlign: "left", fontStyle: 'italic'}}>{content.title}</h2>
                            <div>{content.description}</div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{fontSize:"large", margin:"auto"}}>
                    {content.image != undefined &&
                        <Grid.Column>
                                <Image style={{width:"auto", flex:"auto"}} src={content.image.imgUrl != "" ? content.image.imgUrl : ""} ui={true} fluid />
                        </Grid.Column>
                    }
                </Grid.Row>
            </Grid>
        )
    }

 
    render() {
        return (
            <>

                <SegmentedHeader content="За нас" >
                    <h2>За Нас</h2>
                    <div style={{fontStyle: 'italic'}}>
                        <h3 >Ние сбъдваме мечтата за Вашият Дом</h3>
                    </div>
                    

                  </SegmentedHeader>
                   
                    <Grid >
                        <Grid.Column>
                        {this.state.form != undefined &&
                            this.state.form.map((map, index) => {
                                return (
                                    this.renderContent(map, index)
                                )      
                            })
                        }
                       </Grid.Column>

                    </Grid>

                        {/* <Segment>
                        <Image style={{width:"auto", flex:"auto"}} src={"/uploads/test.jpg"} />
                        </Segment> */}

                     <Dimmer active={this.state.loading} inverted page>
                        <Loader content={this.state.loadingMessage} />
                    </Dimmer>
            </>
        );

}

    


}
    
export const HomePage = HomePageImpl;