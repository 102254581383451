import * as React from "react";
import { Card, Container, Icon, Image } from 'semantic-ui-react';

const PhotoBox = (imgUrl: string, title: string , objectId : number, props?: any) => (

   
   <Card  onClick={(e)=> { props.history.push("object/"+objectId.toString())  }}
   style={{height: "20rem", width:"30rem"}}
         >
        {imgUrl != undefined && imgUrl != "" &&
            // <Image src={imgUrl} wrapped ui={false} size="small" 
            // style={{height:"100%", maxHeight:"80%", background:"center"}} />

            <div className="small image" style={{height:"100%", maxHeight:"80%", background:"center"}}>
                <img src={imgUrl} style={{height:"100%", width:"100%", objectFit: "contain"}}/>

            </div>

        }
        {(imgUrl == undefined || imgUrl == "" ) &&
            <Container ></Container>
        }

        <Card.Content style={{height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}> 
            <Card.Header content={title} />
            {/* <Card.Description>
            </Card.Description> */}
        </Card.Content>
    </Card>
)

export default PhotoBox