
const Protocol = "http";
const Host = "localhost";
const Port = 50931;

module.exports = {
    Protocol,
    Host,
    Port,
};

