import * as React from "react";
import { ObjectImage } from "../Typedefs.gen";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//import settings from  "../local.config"
const settings = require("../local.config");


export const __Protocol__ = JSON.stringify(settings.Protocol);
export const __Host__ = JSON.stringify(settings.Host);
export const __Port__ = JSON.stringify(settings.Port);

export const baseApiPath =   
__Protocol__.substring(1, __Protocol__.length-1) 
+ "://" 
+ __Host__.substring(1, __Host__.length-1) 
+ ":" 
+ __Port__ ;



type RenderElement = JSX.Element | null;

interface IProps {
}

interface IState {
};


type TPropsImpl = IProps;
type TStateImpl = IState;

class BasePageImpl<P, S> extends React.Component<P, S> {

    constructor(props: P) {
        super(props);


    }

   


    setDocumentTitle(pageName: string) {
        document.title = pageName;
    }

    handleObjectImage = async (data: any) => {
        var resultImage : ObjectImage;

        await this.readFileDataAsBase64(data[0]).then((result) => {
               resultImage = {
                    id: 0,
                    imgUrl: "",
                    thumbnail: "",
                    fileContent: result + "",
                    fileName: data[0].name
                }
        });
        
        
    }
   
    readFileDataAsBase64(file: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsDataURL(file);
        });
    }
    
    centerForm(element: RenderElement) {
        // if (!navigator.cookieEnabled && !Session.hasAgreedToUseWithoutCookieSupport()) {
        //     return <Redirect to="/cookie-support" />
        // }

        const internal = <div className="internal-container-form">{element}</div>;
        const external = <div className="external-container-form">{internal}</div>;

        return external;
    }

}

 

export const BasePage = BasePageImpl;