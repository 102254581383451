import * as React from "react";
import { BasePage } from "./BasePage";
import { RouteComponentProps } from "react-router-dom";
import { SegmentedHeader } from "../components/Shared/SegmentedHeader";
import { Grid } from "semantic-ui-react";
import { MyMapComponent } from "../components/Shared/GoogleMap";

interface IProps {
}

interface IState {
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class ContactsPageImpl extends BasePage<TPropsImpl, TStateImpl> {
  
    constructor(props: TPropsImpl) {
        super(props);

        this.state = {
        };

    }

    componentDidMount() {
        this.setDocumentTitle("Връзка с нас");
    }

    componentWillUnmount() {
    }

    navigate(link: string) {
        this.props.history.push( link);
    }
 
    render() {
        return (
            <>
                <SegmentedHeader content="Свържете се с нас" />
                   
                    <Grid columns={2} doubling>
                            <Grid.Column > 
                                <div>
                                    <h2>Уест Хаус ЕООД</h2> 
                                    <p>гр.София, ж.к."Красно село", ул."Хубча" 2, Офис 3</p>
                                    <p>тел.: 0898 53 56 77</p>
                                    <p>email: info@westhouse.bg</p>
                                </div>
                            </Grid.Column>
                        
                            <Grid.Column > 
                                <MyMapComponent
                                        isMarkerShown
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCiChEoPTK6qDC4Gm3KK8wGXPtzpzZHupI&v=3.exp&libraries=geometry,drawing,places"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `400px`, width: `400px`}} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        cordinateLat={42.677089852484734}
                                        cordinateLng={23.286120673645893}
                                        title="West House"
                                        />
                            </Grid.Column>

                    </Grid>
                     

            </>
        );

}

    


}
    
export const ContactsPage = ContactsPageImpl;