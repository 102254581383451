import { isNullOrUndefined } from "util";
import { UserType } from "../../../Typedefs.gen";

export class Session {
    static readonly KEY_NAME = "auth";
    static readonly AUTH_KEY_VALUE = "hasKey";
    static readonly ID_KEY = "id";
    static readonly NAME_KEY = "name";
    static readonly PERMISSIONS_KEY = "permissions";
    static readonly PREV_PATH_KEY = "prevPath";
    static readonly MISSING_COOKIE_SUPPORT_AGREEMENT_KEY = "cookie";

    static hasAuthKey() {
        return sessionStorage.getItem(Session.KEY_NAME) != null;
    }

    static getAuthKey() {
        return sessionStorage.getItem(Session.KEY_NAME);
    }

    static initializeAuthKey() {
        sessionStorage.setItem(Session.KEY_NAME, Session.AUTH_KEY_VALUE);
    }

    static setAuthKey(value: string) {
        sessionStorage.setItem(Session.KEY_NAME, value);
    }

    static clearAuthKey() {
        sessionStorage.removeItem(Session.KEY_NAME);
    }

    static setId(id: number) {
        sessionStorage.setItem(Session.ID_KEY, id.toString());
    }

    static getId(): number {
        var strId = sessionStorage.getItem(Session.ID_KEY);

        return isNullOrUndefined(strId) ? 0 : parseInt(strId);
    }

    static setName(name: string) {
        sessionStorage.setItem(Session.NAME_KEY, name);
    }

    static getName(): string {
        return sessionStorage.getItem(Session.NAME_KEY) || "";
    }

    static setPermissions(permissions: UserType[]) {
        var permissionsStr = JSON.stringify(permissions);
        sessionStorage.setItem(Session.PERMISSIONS_KEY, permissionsStr);
    }

    static getPermissions(): UserType[] {
        var permissionsStr = sessionStorage.getItem(Session.PERMISSIONS_KEY);
        return permissionsStr != null ? JSON.parse(permissionsStr) as UserType[] : [];
    }

    static hasPermission(permission: UserType) {
        return this.getPermissions().includes(permission);
    }





    static agreeToUseWithoutCookieSupport() {
        sessionStorage.setItem(Session.MISSING_COOKIE_SUPPORT_AGREEMENT_KEY, "true");
    }

    static hasAgreedToUseWithoutCookieSupport(): boolean {
        return sessionStorage.getItem(Session.MISSING_COOKIE_SUPPORT_AGREEMENT_KEY) != null;
    }

    static getPrevPath(): string {
        return sessionStorage.getItem(Session.PREV_PATH_KEY) || "";
    }

    static setPrevPath(path: string) {
        sessionStorage.setItem(Session.PREV_PATH_KEY, path);
    }

    static clearPrevPath() {
        sessionStorage.removeItem(Session.PREV_PATH_KEY);
    }

    static hasPrevPath() {
        let path = sessionStorage.getItem(Session.PREV_PATH_KEY);
        return path != null && path != "";
    }
}