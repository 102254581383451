import * as React from 'react';
import ReactImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import { BasePage } from '../../Pages/BasePage';
import { ObjectImage } from '../../Typedefs.gen';

let gallery: ReactImageGallery | null;

interface IProps {
    images: ObjectImage[],
    note: string,
  }

interface IState {
    imagesGalleryItem: ReactImageGalleryItem[]
}


type IPropsTmpl = IProps;
type IStateTmpl = IState;

class ImageGalleryImpl extends BasePage<IPropsTmpl,IStateTmpl > {

    constructor(props: IPropsTmpl) {
        super(props);
      this.state = {
        imagesGalleryItem: this.loadPropsImages(),
      }
      
    }

    componentDidMount() {
        if (gallery) {
            const message = `Showing ${gallery.getCurrentIndex() + 1}. image the gallery.`;
        }
    }

    loadPropsImages() {
        return (
            
                this.props.images.map(item => ({
                    original: item.imgUrl,
                    thumbnail: item.thumbnail,
                    originalTitle: item.title,
                    bulletClass: 'my-bullet-class-name',
                    originalClass: 'featured-slide',
                    thumbnailClass: 'featured-thumb',
                    description: "",
                    sizes: "small",
                }))
        )

    }


      
      
    onBeforeSlide(index: number) {
        const message = `onBeforeSlide ${index}`;
    }

    renderItem(item: ReactImageGalleryItem): React.ReactNode {
        return (
            <div className="image-gallery-thumbnail-inner">
                <img src={item.original} alt={item.originalAlt} title={item.originalTitle} style={{width:"100%", maxHeight:"80vh", objectFit: "contain"}}/>
            </div>
        );
    }

    renderThumbInner(item: ReactImageGalleryItem): React.ReactNode {
        return (
            <div className="image-gallery-thumbnails-container">
                <img src={item.thumbnail} alt={item.thumbnailAlt} title={item.thumbnailTitle} />
                {item.thumbnailLabel && <div className="image-gallery-thumbnail-label">{item.thumbnailLabel}</div>}
            </div>
        );
    }

    render() {
        // const galleryItem: ReactImageGalleryItem = {
        //     original: 'https://imgproxy.natucate.com/PAd5WVIh-tjEKQM4Z6tm6W1J4Yc2JIYWrKEroD1c7mM/rs:fill/g:ce/w:3840/h:2160/aHR0cHM6Ly93d3cubmF0dWNhdGUuY29tL21lZGlhL3BhZ2VzL3JlaXNlYXJ0ZW4vNmMwODZlYmEtNzk3Yi00ZDVjLTk2YTItODhhNGM4OWUyODdlLzM3NjYwMTQ2NjMtMTU2NzQzMzYxMi8xMl9kYW5pZWxfY2FuX2JjLTIwNy5qcGc',
        //     thumbnail: 'https://imgproxy.natucate.com/PAd5WVIh-tjEKQM4Z6tm6W1J4Yc2JIYWrKEroD1c7mM/rs:fill/g:ce/w:3840/h:2160/aHR0cHM6Ly93d3cubmF0dWNhdGUuY29tL21lZGlhL3BhZ2VzL3JlaXNlYXJ0ZW4vNmMwODZlYmEtNzk3Yi00ZDVjLTk2YTItODhhNGM4OWUyODdlLzM3NjYwMTQ2NjMtMTU2NzQzMzYxMi8xMl9kYW5pZWxfY2FuX2JjLTIwNy5qcGc',
        //     originalTitle: 'My Logo',
        //     bulletClass: 'my-bullet-class-name',
        //     originalClass: 'featured-slide',
        //     thumbnailClass: 'featured-thumb',
        //     sizes:"small",
        //     description: "description description description description description description description description description description "
        // };
        // const galleryItem2: ReactImageGalleryItem = {
        //     original: 'https://natureconservancy-h.assetsadobe.com/is/image/content/dam/tnc/nature/en/photos/Zugpsitze_mountain.jpg?crop=0,176,3008,1654&wid=4000&hei=2200&scl=0.752',
        //     thumbnail: 'https://natureconservancy-h.assetsadobe.com/is/image/content/dam/tnc/nature/en/photos/Zugpsitze_mountain.jpg?crop=0,176,3008,1654&wid=4000&hei=2200&scl=0.752=w1000',
        //     originalTitle: 'My Logo',
        //     bulletClass: 'my-bullet-class-name',
        //     originalClass: 'featured-slide',
        //     thumbnailClass: 'featured-thumb',
        //     sizes:"small",

        // };
        // const galleryItem3: ReactImageGalleryItem = {
        //     original: 'https://thumbs-prod.si-cdn.com/DTC0Tm-OasgkRvO_yQgsytW-sE0=/fit-in/1600x0/https://public-media.si-cdn.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg',
        //     thumbnail: 'https://thumbs-prod.si-cdn.com/DTC0Tm-OasgkRvO_yQgsytW-sE0=/fit-in/1600x0/https://public-media.si-cdn.com/filer/04/8e/048ed839-a581-48af-a0ae-fac6fec00948/gettyimages-168346757_web.jpg',
        //     originalTitle: 'My Logo',
        //     bulletClass: 'my-bullet-class-name',
        //     originalClass: 'featured-slide',
        //     thumbnailClass: 'featured-thumb',
        //     sizes:"small",
        // };
        // const galleryItem4: ReactImageGalleryItem = {
        //     original: 'http://localhost:9000/images/logo.jpg',
        //     thumbnail: 'http://localhost:9000/images/logo.jpg',
        //     originalTitle: 'My Logo',
        //     bulletClass: 'my-bullet-class-name',
        //     originalClass: 'featured-slide',
        //     thumbnailClass: 'featured-thumb',
        //     sizes:"small",
        //     description: "description description description description description description description description description description "
        // };


        const props: ReactImageGalleryProps = {
            items: this.state.imagesGalleryItem,
            autoPlay: false,
            showPlayButton: false,
          //   renderThumbInner: this.renderThumbInner,
             renderItem:this.renderItem,
            disableKeyDown: false,
            showThumbnails: true,
            showBullets:true,
            showIndex:true,
        };

        return <ReactImageGallery ref={r => (gallery = r)} {...props}  />;
    }
}

export const ImageGallery = ImageGalleryImpl;