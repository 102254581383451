import * as React from "react";
import { RouteComponentProps } from "react-router";
import { BasePage } from "./BasePage";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import { SegmentedHeader } from "../components/Shared/SegmentedHeader";
import { ApiRequest } from "../components/Shared/Network/RequestHelper";
import { PriceType, PricingPlan } from "../Typedefs.gen";
import { Session } from "../components/Shared/Network/Session";

interface IProps {
}

interface IState {
   form: PricingPlan[];
   loading: boolean;
   loadingMessage: string;
   admin: boolean;
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class PricesPageImpl extends BasePage<TPropsImpl, TStateImpl> {
  
    constructor(props: TPropsImpl) {
        super(props);

        this.state = {
            form:[],
            loading: false,
            loadingMessage: "",
            admin: props.location.pathname.substr(0,7) == "/admin/",
        };
    }

    componentDidMount() {

        if(!Session.hasAuthKey() && this.state.admin){
            this.navigate("/prices")
        }

        this.setDocumentTitle("Prices");
        this.loadData();
    }

    loadData() {
        this.setState({loading: true, loadingMessage: "Loading prices ..."});

        ApiRequest.get("/api/PricingPlan/All").then(r => {
            let data: PricingPlan[] = r.data as PricingPlan[];
            this.setState({form: data, loading: false, loadingMessage: ""});
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    navigate(link: string) {
        this.props.history.push(link);
    }

    onPriceClicked(id : number) {
        this.navigate("price/"+id.toString());
    }

    render() {
        return (
            <>
                <SegmentedHeader content={"Цени" + (this.state.admin ? " - Редактиране" : "")  }/>

                {this.state.admin && 
                    <Grid centered>
                        <Grid.Row>
                            <Button primary content="Добави нова цена" onClick={() => this.navigate("/admin/price")} />
                        </Grid.Row>
                    </Grid>
                }


                <Grid container centered>
                    <Grid.Row centered>
                        <div className="pricing-table">
                            {this.state.form != undefined &&   this.state.form.map((m, i) => {
                                 return (
                                     // is-info, is-success, is-link, is-dark, 
                                    <div className={ (m.type == PriceType.Warning) ? "pricing-plan is-warning" : (m.type == PriceType.Active ? "pricing-plan is-active" : (m.type == PriceType.Danger ? "pricing-plan is-danger" : "pricing-plan is-info"))} key={i}>
                                        <div className="plan-header">{m.title}</div>
                                        <div className="plan-price"><span className="plan-price-amount"><span className="plan-price-currency">{m.currency}</span>{m.amount}</span>{m.amountType}</div>
                                        <div className="plan-items">
                                           
                                            {m.descriptions.map((f, index) => {
                                                return(<div className="plan-item" key={index}>{f}</div>)
                                             })}

                                            <div className="plan-item">-</div>
                                        </div>
                                        <div className="plan-footer">
                                            <button className="button is-fullwidth" onClick={() => this.onPriceClicked(m.id)}>{this.state.admin ? "Редактирай" : "Избери"}</button>
                                        </div>
                                    </div>
                                 )   
                            })}
                        </div>
                    </Grid.Row>

                    <Grid.Row centered>
                        <div>Цени валидни от 01.06.2021г.</div>
                    </Grid.Row>
                </Grid>

                <Dimmer active={this.state.loading} inverted page>
                    <Loader content={this.state.loadingMessage} />
                </Dimmer>

            </>);
    }



}

export const PricesPage = PricesPageImpl;