import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

interface IProps {

}

interface IState {
  isLogedin: boolean;
}


export class Layout extends Component<IProps, IState > {
  static displayName = Layout.name;

  render () {
    return (
      <div className='body' >
        {this.renderTopMenu()}

        <Container className='body-ext'>
          {this.props.children}
        </Container>

        <footer className='footer-ext'>
                Copyright ® 2021 West House
        </footer>
         
        {this.renderToast()}
        
      </div>
    );
  }


  renderTopMenu(){
    return (
      <NavMenu IsLogedIn={true}/>
    )
  }

  renderToast(){
    return(
      <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
    )
  }

}
