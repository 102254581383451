import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import * as ReactDOM from "react-dom";
import * as ReactDom from "react-dom";

import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

// const Root = document.querySelector("#Root");

// const Render =
//             <BrowserRouter >
//               <App />
//             </BrowserRouter>;

// ReactDOM.render(Render, document.getElementById('root'));

// const rootElement = document.getElementById('root');

// ReactDOM.render(
//   <BrowserRouter >
//     <App />
//   </BrowserRouter>,
//   rootElement);

  const Root = document.querySelector("#root");
const Render =
    <BrowserRouter>
        <App />
    </BrowserRouter>;
    
ReactDOM.render(Render, Root);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

