import * as React from "react";
import { RouterProps } from "react-router-dom";
import { Button, Dimmer, Form, Header, Loader, Message, Segment } from "semantic-ui-react";
import { CenterGrid } from "../components/Shared/CenterGrid";
import { Session } from "../components/Shared/Network/Session";
import { TEventHtmlInput, TInputChange } from "../Typedefs";
import { defaultChangeEventData, enumValueToKeys, LoginDto, UserType } from "../Typedefs.gen";
import { baseApiPath, BasePage } from "./BasePage";
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { ApiRequest } from "../components/Shared/Network/RequestHelper";

class AuthenticationController {
    static authenticate(data: LoginDto){
        return ApiRequest.post<void>("/api/authentication/authenticate", data);
    }
    static logout(data: any){
        return ApiRequest.post<void>("/api/authentication/logout", data);
    }
    static IsLoggedIn(){
        return ApiRequest.get<void>("/api/authentication/IsLoggedIn");
    }
}

export const KEY_PERMISSIONS = "permissions"
export const ID_KEY = "id";

enum FormFields {
    Email = "email",
    Password = "password"
}

interface IForm {
    email: string;
    password: string;
}

interface IProps {
}

interface IState {
    form: IForm;
    loading: boolean;
    loadingMessage: string;
    error?: any;
}

type TPropsImpl = IProps & RouterProps;
type TStateImpl = IState;

class LoginPageImpl extends BasePage<TPropsImpl, TStateImpl> {

    constructor(props: TPropsImpl) {
        super(props);

        this.state = {
            form: enumValueToKeys(FormFields),
            loading: false,
            loadingMessage: ""
        };
    }

    componentDidMount() {
        if(Session.hasAuthKey()){
            this.props.history.push("/");
        } else {
            this.checkForActiveSession();
        }
    }

    navigate(link: string) {
        this.props.history.push(link);
    }

    onChange = (e: TEventHtmlInput, data: TInputChange) => {
        const nextState = defaultChangeEventData(this.state.form, data);
        this.setState(nextState);
    }

    checkForActiveSession = () => {
        this.setState({ loading: true, loadingMessage: "Checking for active session..." });

        AuthenticationController.IsLoggedIn().then(r => {
            this.setState({loading: false, loadingMessage: ""});

            const data = r.data as any;
            this.onLoginSuccess(data);          
            this.props.history.push("/");
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })
    };

    
    onLogin = (e: any, data: any) => {
        this.setState({loading: true});
        AuthenticationController.authenticate(this.state.form).then(r => {
            const data = r.data as any;
            this.onLoginSuccess(data);          
            window.location.reload(false)
        }).catch(r => {
            this.setState({loading: false});
            this.handleError(r);
        });
    };

    onLoginSuccess = (data: any) => {
        localStorage.setItem(KEY_PERMISSIONS, data.permissions);
        localStorage.setItem(ID_KEY, data.userId);

        let permission : UserType[]  = [];
        permission.push(data.permissions);

        Session.setId(data.userId);
        Session.setPermissions(permission);
        Session.initializeAuthKey();
    }

    renderError() {
        const error = this.state.error;
        if(error == null) {
            return null;
        }

        return (
            <Message error={true} 
                header={error.title}
                content={error.details}
            />
        );
    }

    handleError = (e: any) => {
        this.setState({error: e.error});
    }

    render() {
        const errorMessage = this.renderError();
        return (
           <CenterGrid>
               <Dimmer active={this.state.loading} inverted>
                    <Loader content={this.state.loadingMessage} />
                </Dimmer>
                <Header as="h2" color="teal" textAlign="center">
                    Log-in to your account
                </Header>
                    {errorMessage}
                <Form size="large">
                    <Segment stacked={true}>
                        <Form.Input value={this.state.form.email} onChange={this.onChange} fluid={true} icon="user" iconPosition="left" placeholder="E-mail address" name={FormFields.Email} />
                        <Form.Input
                            value={this.state.form.password}
                            onChange={this.onChange}
                            name={FormFields.Password}
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Password"
                            type="password"
                        />

                        <Button color="teal" fluid size="large" onClick={this.onLogin}>
                            Login
                        </Button>
                    </Segment>
                </Form>
           </CenterGrid>
        );
    }
}

export const LoginPage = LoginPageImpl;