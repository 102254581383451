import React, { Component } from 'react';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { UserType } from '../Typedefs.gen';
import { Session } from './Shared/Network/Session';
import { ApiRequest } from './Shared/Network/RequestHelper';
import { ID_KEY, KEY_PERMISSIONS } from '../Pages/LoginPage';
import { Button, Icon } from 'semantic-ui-react';




export class NavMenu extends Component<any,any> {
  static displayName = NavMenu.name;

  constructor (props: any) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.state = {
      collapsed: true, 
      permission:  Session.hasPermission(UserType.Administrator),
      isLogedin: Session.hasAuthKey(),
    };
  }

 componentDidMount(){
  if(!Session.hasAuthKey()){
        this.checkForActiveSession();
    }
 }


  checkForActiveSession(){
    ApiRequest.get("/api/authentication/IsLoggedIn").then(r => {
      const data = r.data as any;
      if(data != undefined && data.successful == true){
          this.onLoginSuccess(data); 
          this.setState({isLogedin: Session.hasAuthKey()});
      }
    })
  }

  onLoginSuccess = (data: any) => {
    localStorage.setItem(KEY_PERMISSIONS, data.permissions);
    localStorage.setItem(ID_KEY, data.userId);

    let permission : UserType[]  = [];
    permission.push(data.permissions);

    Session.setId(data.userId);
    Session.setPermissions(permission);
    Session.initializeAuthKey();
  }


  toggleNavbar () {

    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  toggleNavbarFromMenu(){

    if(!this.state.collapsed){
      this.toggleNavbar();
    }
  }

  onLogout = () => {
    ApiRequest.post("/api/authentication/logout").then(r => {
            Session.clearAuthKey();
            this.setState({isLogedin: false});
      });
  }

  render () {
    return (
      <header className='header-navmenu'>
        <Navbar  className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
           
            <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem className='button-animated'>
                  <span>
                    <NavLink tag={Link} className="text-dark" to="/" onClick={() => this.toggleNavbarFromMenu()}>За нас</NavLink>
                    </span>
                </NavItem>
                <NavItem className='button-animated'>
                  <span>
                  <NavLink tag={Link} className="text-dark" to="/objects" onClick={() => this.toggleNavbarFromMenu()}>Нашите обекти</NavLink>
                  </span>
                </NavItem>
                <NavItem className='button-animated'>
                  <span>
                  <NavLink tag={Link} className="text-dark" to="/prices" onClick={() => this.toggleNavbarFromMenu()}>Цени</NavLink>
                  </span>
                </NavItem>
                <NavItem className='button-animated'> <span>
                    <NavLink tag={Link} className="text-dark" to="/contacts" onClick={() => this.toggleNavbarFromMenu()}>Контакти</NavLink>
                    </span>
                </NavItem>

                 { this.state.isLogedin &&           
                      <UncontrolledDropdown setActiveFromChild>
                        <DropdownToggle tag="a" className="nav-link" caret>
                            АДМИНИСТРИРАНЕ
                        </DropdownToggle>

                        <DropdownMenu>
                          <DropdownItem tag="a" href="/admin/home" className="text-dark">За Нас</DropdownItem>
                          <DropdownItem tag="a" href="/admin/objects" className="text-dark">Нашите обекти</DropdownItem>
                          <DropdownItem tag="a" href="/admin/prices" className="text-dark">Цени</DropdownItem>
                          <DropdownItem tag="a" href="/admin/contacts" className="text-dark">Контакти</DropdownItem>
                        </DropdownMenu>

                      </UncontrolledDropdown>
                 
                  }

                   { this.state.isLogedin && 
                                
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/" onClick={this.onLogout} >Logout</NavLink>
                    </NavItem>
                  }

              </ul>
            </Collapse>
            <NavbarBrand tag={Link} to="/">WestHouse</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          </Container>
        </Navbar>
      </header>
    );
  }
}
