import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Container, Dimmer, Divider, Grid, Label, Loader, Segment } from "semantic-ui-react";
import { Image } from 'semantic-ui-react';
import { ImageGallery } from "../components/Shared/ImageGallery";
import { ApiRequest } from "../components/Shared/Network/RequestHelper";
import { initialObjectInfo, ObjectImage, ObjectInfo } from "../Typedefs.gen";
import { BasePage } from "./BasePage";

interface IProps {
}

interface IState {
    id: number;
    mode: Mode;
    form: ObjectInfo;
    loading: boolean;
    loadingMessage: string;
}

enum Mode {
    Create,
    Edit
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class ObjectPageImpl extends BasePage<TPropsImpl, TStateImpl> {
    GalleryItems: ObjectImage[] = [];

    constructor(props: TPropsImpl) {
        super(props);

        var idNum = parseInt(props.match.params.id as string);
        var id = isNaN(idNum) ? -1 : idNum;
        var mode = isNaN(idNum) ? Mode.Create : Mode.Edit;

        this.state = {
            id: id,
            mode: mode,
            form: initialObjectInfo,
            loading: false,
            loadingMessage:"",
        };

         }

    componentDidMount() {
        this.setDocumentTitle("Нашите обекти");
        this.loadData();

        if(this.state.mode == Mode.Edit ){
            
        } 
        else {

        }
    }
    loadData() {
        this.setState({loading: true, loadingMessage: "Loading object ..."});

        ApiRequest.post("/api/objects/GetById", { id:this.state.id}).then(r => {
            let data: ObjectInfo = r.data as ObjectInfo;
            this.setState({form: data, loading: false, loadingMessage: ""});
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })

    }

    componentWillUnmount() {
    }

    navigate(link: string) {
        this.props.history.push(link);
    }

    renderImage(){
        return(
            <div style={{width:"auto"}}>
                { this.state.form.objectImageItems.length>0 &&
                    <Image style={{width:"auto"}} src={this.state.form.objectImageItems[0]?.imgUrl ?? ""} ui={true} fluid />
                }
            </div>

        )
    }

    render() {
        return (
            <>


                <Grid padded='horizontally' >
                    <Grid.Row style={{height: '35%'}}>
                        <Grid.Column width={6}>
                            {this.renderImage()}
                        </Grid.Column>
                        <Grid.Column width={10} style={{overflowWrap: 'break-word'}}>
                            <div>
                                <h2 style={{ textAlign: "left", fontStyle: 'italic'}}>{this.state.form.title}</h2>
                                <div>{this.state.form.description}</div>

                            </div>

                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row style={{height: '65%'}}>
                        <Grid.Column width={16} >
                            { this.state.form.objectImageItems.length > 1 &&
                                <ImageGallery images={this.state.form.objectImageItems.filter(f => 
                                    f.id != this.state.form.objectImageItems[0].id )} note="test note" />
                            }   
                        </Grid.Column>
                    </Grid.Row>
                    </Grid>

                    <Dimmer active={this.state.loading} inverted page>
                        <Loader content={this.state.loadingMessage} />
                    </Dimmer>
            </>);
    }



}

export const ObjectPage = ObjectPageImpl;