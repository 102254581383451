export enum RequestType {
    Get = "get",
    Post = "post",
    Put = "put",
    Patch = "patch",
    Delete = "delete"
}

export enum Protocol {
    None = "none",
    Auto = "auto",
    Http = "http",
    Https = "https"
}
