import * as React from "react";
import { RouteComponentProps } from "react-router";
import { BasePage } from "./../BasePage";
import { Button, Confirm, Container, Dimmer, DropdownProps, Form, Grid, Image, InputOnChangeData, Loader, Segment, TextAreaProps } from "semantic-ui-react";
import { SegmentedHeader } from "../../components/Shared/SegmentedHeader";
import { ApiRequest } from "../../components/Shared/Network/RequestHelper";
import { initialObjectImage, ObjectImage, PageContent, PageType, PositionContentType, PriceType, PricingPlan } from "../../Typedefs.gen";
import { Session } from "../../components/Shared/Network/Session";
import { toast } from "react-toastify";
import { FormEvent } from "react";

const initPricingPlan: PricingPlan = {
    id: -1,
    type: PriceType.Error,
    title: "",
    currency: "",
    amount: "",
    amountType: "",
    descriptions: [""],
    pageContents: [],
    order:0,

}

const positionOptions = [
    { key: '0', value: '0', text: 'Снимка-Ляво Текст-Дясно' },
    { key: '1', value: '1', text: 'Снимка-Дясно Текст-Ляво' },
    { key: '2', value: '2', text: 'Снимка-Горе Текст-Долу' },
    { key: '3', value: '3', text: 'Снимка-Долу Текст-Горе' }
]

const priceTypeOptions = [
    { key: PriceType.Error.toString(), value: PriceType.Error, text: 'Тип 1' },
    { key: PriceType.Warning.toString(), value: PriceType.Warning, text: 'Тип 2' },
    { key: PriceType.Active.toString(), value: PriceType.Active, text: 'Тип 3' },
    { key: PriceType.Danger.toString(), value: PriceType.Danger, text: 'Тип 4' }
]


interface IProps {
}

interface IState {
    id: number;
    form: PricingPlan;
    priceDescription: any;
    uploadFileLoading: boolean;
    loading: boolean;
    loadingMessage: string;
    confirmSave: boolean;
    confirmCancel: boolean;
    confirmDelete: boolean;
    confirmContentDelete: boolean;
    indexContent: number;
    isMobile: boolean;
}

type TPropsImpl = IProps  & RouteComponentProps<any>;
type TStateImpl = IState;

class PriceEditPageImpl extends BasePage<TPropsImpl, TStateImpl> {
  
    constructor(props: TPropsImpl) {
        super(props);

        var idNum = parseInt(props.match.params.id as string);
        var id = isNaN(idNum) ? -1 : idNum;
        
        this.state = {
            id: id,
            form: initPricingPlan,
            priceDescription: "",
            uploadFileLoading: false,
            loading: false,
            loadingMessage: "",
            confirmSave: false,
            confirmCancel: false,
            confirmDelete: false,
            confirmContentDelete: false,
            indexContent: 0,
            isMobile: false,
        };

    }

    componentDidMount() {
        if(!Session.hasAuthKey()){
            this.navigate("/prices")
        }


        this.setDocumentTitle("Price editing");
        if(this.state.id > 0) {
            this.loadData();

        }

    }

    loadData() {
        this.setState({loading: true, loadingMessage: "Loading PricingPlan ..."});

        ApiRequest.post("/api/PricingPlan/GetById", {id: this.state.id}).then(r => {
            if(r.successful){
                let data: PricingPlan = r.data as PricingPlan;
                if(data.descriptions.length < 1){
                    data.descriptions.push("");
                }
                this.setState({form: data});
            } else {
                toast.error("Възникна грешк апри извличането на данни!");
            }
            this.setState({loading: false, loadingMessage: ""});
        }).catch(r => {
            this.setState({loading: false, loadingMessage: ""});
        })
    }

    navigate(link: string) {
        this.props.history.push(link);
    }

    onFormInputChanged = (event: any, data: InputOnChangeData) => {
        this.changeFormProp(data.name, data.value);
    }

    onFormContentInputChanged = (event: any, data: InputOnChangeData) => {
        this.changeContentFormProp(data.name, data.value, data.index);
    }

    onFormTextAreaChanged = (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        let msg = data.value ?  data.value.toString() : "";
        this.changeContentFormProp(data.name, msg, data.index);
    }
    
    onFormInputDescriptionChanged = (event: any, data: InputOnChangeData) => {
        let descriptions = this.state.form.descriptions;

        descriptions[data.index] = data.value;

        var newForm = { ...this.state.form, descriptions: descriptions };
        this.setState({ form: newForm });
    }

    onFormSelectChanged = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.changeFormProp(data.name, data.value);
    };
    
    changeContentFormProp(name: string, data: any, index: number) {
        let newForm = this.state.form;

        if(name === "title"){
            newForm.pageContents[index].title = data as string;
        } else if(name === "description"){
            newForm.pageContents[index].description = data as string;
        } else  if(name === "position"){
            newForm.pageContents[index].position = Number(data);
        }  

        this.setState({ form: newForm });
    }

    changeFormProp(name: string, data: any) {
        var newForm = { ...this.state.form, [name]: data };
        this.setState({ form: newForm });
    }

    onFormInputFileChanged = async (event: any, index: any) => {
        var files = event.target.files;

        await this.readFileDataAsBase64(files[0]).then((result) => {

            let newForm = this.state.form;
            
            newForm.pageContents[index].image.fileContent = result as string;
            newForm.pageContents[index].image.fileName = files[0].name;
            newForm.pageContents[index].image.imgUrl = URL.createObjectURL(files[0]);

            this.setState({ form: newForm });
        });
    }


    addNewDescription(){

        let data = this.state.form.descriptions;
        data.push("");

        var newForm = { ...this.state.form, descriptions: data };
        this.setState({ form: newForm });
    }

    addNewContent(){
        let newForm = this.state.form;

        let initObjectImage: ObjectImage ={
            id:-1,
            imgUrl:"",
            thumbnail:"",
            title:"",
        }

        let newContent: PageContent = {
            id:-1,
            order: 0,
            title:"",
            description:"",
            image: initObjectImage,
            position: PositionContentType.LeftImageRightText,
            pageType: PageType.Price,
        }

        newForm.pageContents.push(newContent);
        this.setState({ form: newForm });
    }

    deleteContent(){
        let newForm = this.state.form;
        newForm.pageContents.splice(this.state.indexContent,1);

        this.setState({ form: newForm, confirmContentDelete: false });
    }

    save(){
        if (this.state.form.id > 0){
            this.edit();
        } else {
            this.create();
        }
    }

    create(){
        this.setState({loading: true, loadingMessage:"Loading creating pricing plan ...", confirmSave: false})

        ApiRequest.post("/api/PricingPlan/create", this.state.form).then(r => {
            if(r.successful){
                this.navigate("/admin/prices");
                toast.success("Данните са запазени!");
            } else {
                toast.error("Възникна грешка!");
            }
         
            this.setState({loading: false, loadingMessage: ""});
        }).catch(r => {
            toast.error("Възникна проблем! Данните не са запазени!");
            this.setState({loading: false, loadingMessage: ""});
        })
    }

    edit(){
        this.setState({loading: true, loadingMessage:"Loading creating pricing plan ...", confirmSave: false})

        ApiRequest.post("/api/PricingPlan/update", this.state.form).then(r => {
            if(r.successful){
                this.navigate("/admin/prices");
                toast.success("Данните са запазени!");
            } else {
                toast.error("Възникна грешка!");
            }

            this.setState({loading: false, loadingMessage: ""});
        }).catch(r => {
            toast.error("Възникна проблем! Данните не са запазени!");
            this.setState({loading: false, loadingMessage: ""});
        })
    }

    delete(){
        this.setState({loading: true, loadingMessage:"Loading delete pricing plan ...", confirmDelete: false})

        ApiRequest.delete("/api/PricingPlan/"+this.state.form.id.toString()).then(r => {
            if(r.successful){
                this.navigate("/admin/prices");
                toast.success("Данните са Изтрити!");
            } else {
                toast.error("Възникна грешка!");
            }
            this.setState({loading: false, loadingMessage: ""});
        }).catch(r => {
            toast.error("Възникна проблем! Данните не са Изтрити!");
            this.setState({loading: false, loadingMessage: ""});
        })
    }

    cancel(){
        this.navigate("/admin/prices");
    }

    renderLeftImageRightText(content: PageContent, indexObj: any){
        return (
        <Segment key={indexObj}>
            <Grid  centered>
                <Grid.Row columns={2} style={{fontSize:"large", margin:"auto"}}>
                    <Grid.Column width={this.state.isMobile ? 16 : 6} style={{float:"right", margin:"auto"}}>
                                <Grid.Row style={{ margin:"auto"}}>
                                    {content.image != undefined && 
                                        <Image style={{width:"auto", flex:"auto"}} src={content.image.imgUrl != "" ? content.image.imgUrl : ""} ui={true} fluid />
                                    }
                                </Grid.Row>
                                <Grid.Row style={{ margin:"auto"}}>
                                   <Grid.Column style={{ margin:"auto"}}>
                                        <Button compact style={{margin:"1px"}}>
                                            <label htmlFor={`files-${indexObj}`}  >Прикачи снимка
                                                <input hidden
                                                    onChange={(event) =>this.onFormInputFileChanged(event, indexObj)}
                                                    accept=".jpg"
                                                    type="file"
                                                    id={`files-${indexObj}`} 
                                                    style={{ visibility: "hidden" }}
                                                    key={`input-${indexObj}`} 
                                                />
                                                </label>
                                            </Button>
                                        </Grid.Column>
                                    <Grid.Column>

                                    <Form.Select 
                                        name="position"
                                        index={indexObj} 
                                        options={positionOptions}
                                        value={content.position.toString()}
                                        onChange={this.onFormSelectChanged}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                          
                    </Grid.Column>
                   
                    <Grid.Column width={this.state.isMobile ? 16 : 10} >
                        <React.Fragment key={indexObj} >
                            <Form.Group widths="16">
                                <Form.Field width={16}>
                                    <label>Заглавна част</label>
                                    <Form.Input
                                        index={indexObj} 
                                        key={`t-${indexObj}`} 
                                        name="title"
                                        value={content.title}
                                        onChange={this.onFormContentInputChanged}
                                        />
                                </Form.Field>
                            </Form.Group >
                            <Form.Group >
                                <Form.Field width={16}>
                                    <label>Съдържание</label>
                                    <Form.TextArea 
                                        index={indexObj} 
                                        key={`d-${indexObj}`} 
                                        name="description"
                                        value={content.description}
                                        onChange={this.onFormTextAreaChanged}
                                        />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group style={{float:"right", margin:"auto"}}>
                                <Grid.Row >
                                    <Button icon="trash alternate" primary onClick={() => this.setState({ confirmContentDelete: true, indexContent: indexObj })} />
                                </Grid.Row>
                            </Form.Group>
                        
                            </React.Fragment>
                    </Grid.Column>
                </Grid.Row>
             
            </Grid>
        </Segment>
        )
    }
  
    renderContent(content: PageContent, index: any){
        if (content != undefined) {
            return  this.renderLeftImageRightText(content, index);
        }
     }

    renderPriceDescription(){
        return(
            this.state.form.descriptions.map((map, index) =>
                {
                    return(
                        <Form.Group key={`inputGroup-${index}`}>
                            <Form.Field width={16}>
                                <Form.Input 
                                    key={`inputDescription-${index}`}  
                                    index = {index}
                                    value={map}
                                    onChange={this.onFormInputDescriptionChanged}
                                />
                            </Form.Field>      
                      </Form.Group>
                    )
                }
            )
        )
    }
   
    renderPricePlan(){
        return(
            <Form>
                 <Segment key={this.state.id} style={{background:"papayawhip"}}>
                    <React.Fragment key={this.state.id} >
                        <Form.Group>
                            <Form.Field>
                                <Form.Select 
                                    name="type"
                                    options={priceTypeOptions}
                                    value={this.state.form.type}
                                    onChange={this.onFormSelectChanged}
                                    />

                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths="16">
                            <Form.Field width="6">
                                <label>Заглавна част на Цената</label>
                                <Form.Input
                                    name="title"
                                    value={this.state.form.title}
                                    onChange={this.onFormInputChanged}
                                />
                            </Form.Field>
                            <Form.Field width="5">
                                <label>Сума</label>
                                <Form.Input
                                    name="amount"
                                    value={this.state.form.amount}
                                    onChange={this.onFormInputChanged}
                                />
                            </Form.Field >
                            <Form.Field width="4">
                                <label>Мерна единица</label>
                                <Form.Input
                                    name="amountType"
                                    value={this.state.form.amountType}
                                    onChange={this.onFormInputChanged}
                                />
                            </Form.Field>
                        </Form.Group>

                        <label>Описание</label>
                        {this.renderPriceDescription()}
                        
                        <Button primary icon="add" content="добави ново описание"  onClick={() => this.addNewDescription()} />


                    </React.Fragment>
                </Segment>
            </Form>
        )



    }

    renderPricePlanContent() {
        return <Form >
                    <Segment >
                        <label>Подробно описание на цената </label>
                        {this.state.form != undefined &&
                            this.state.form.pageContents.map((map, index) => {
                                return (
                                    this.renderContent(map, index)
                                );
                            })}

                        <div style={{textAlign: "center"}}> 
                            <Button primary content="Добави подробно описание" onClick={() =>this.setState(() => this.addNewContent())} />
                        </div>

                    </Segment>
                </Form>;
    }

    renderEditorActions() {

        return (
            <Grid centered padded divided>

                <Grid.Row>
                    <Button positive content="Запази" onClick={() =>this.setState({confirmSave: true})} />
                    <Button secondary content="Откажи" onClick={() =>this.setState({confirmCancel: true})} />
                </Grid.Row>
                <Grid.Row>
                    <Button negative icon="trash alternate" content="Изтриване на цената" onClick={() => this.setState({ confirmDelete: true })} />
                </Grid.Row>
            </Grid>
        );

    }
   
    renderConfirm(){
        return (
            <>
            <Confirm
                content="Потвърдете Отказ?"
                open={this.state.confirmCancel}
                onCancel={() => this.setState({ confirmCancel: false })}
                onConfirm={() => this.cancel()}
                cancelButton="No"
                confirmButton="Yes"
            />

            <Confirm
                content="Потвърдете Запис?"
                open={this.state.confirmSave}
                onCancel={() => this.setState({ confirmSave: false })}
                onConfirm={() => this.save()}
                cancelButton="No"
                confirmButton="Yes"
            />
         
            <Confirm
                content="Потвърдете Изтриване?"
                open={this.state.confirmDelete}
                onCancel={() => this.setState({ confirmDelete: false })}
                onConfirm={() => this.delete()}
                cancelButton="No"
                confirmButton="Yes"
            />

            <Confirm
                content="Потвърдете Изтриване на раздел?"
                open={this.state.confirmContentDelete}
                onCancel={() => this.setState({ confirmContentDelete: false })}
                onConfirm={() => this.deleteContent()}
                cancelButton="No"
                confirmButton="Yes"
            />




            </>
        )
    }

    render() {
        return (
            <>
                <SegmentedHeader content={this.state.form.title}/>

                {this.renderPricePlan()}

                {this.renderPricePlanContent()}
              
                {this.renderEditorActions()}
                {this.renderConfirm()}

                <Dimmer active={this.state.loading} inverted page>
                    <Loader content={this.state.loadingMessage} />
                </Dimmer>

            </>);
    }




   
}

export const PriceEditPage = PriceEditPageImpl;