import * as React from "react"
import { SemanticCOLORS, Segment, Header } from "semantic-ui-react";

interface IProps {
    content?: string;
    textAlign?: "center" | "left" | "right";
    size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';
    color?: SemanticCOLORS
    subheaderContent?: React.ReactNode;
}

interface IState {

}

type PropsImpl = IProps;
type StateImpl = IState;

class SegmentedHeaderImpl extends React.Component<PropsImpl, StateImpl>{
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <>
                <Segment className="real-segment" inverted color="brown">
                    <Header textAlign={this.props.textAlign || "center"}
                        size={this.props.size || "huge"} color={this.props.color}>
                        {this.props.children || this.props.content}

                        {this.props.subheaderContent &&
                            <Header.Subheader content={this.props.subheaderContent} />
                        }
                    </Header>
                </Segment>
            </>
        )
    }
}

export const SegmentedHeader = SegmentedHeaderImpl;